.login-form-container {
  background-color: #F6F6F6;
  padding: 31px 40px 80px 40px;

  label {
    display: block;
    max-width: 100%;
    margin-top: -5px;
    margin-bottom: 5px;
    font-weight: 400;
    color: #404040;
  }

  input {
    -webkit-appearance: none;
    border-radius: 4px;
    height: 48px;
    resize: none;
    width: 100%;
    padding: 0 9px 0 14px;
    color: #404040;
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #515151;
    transition: border-color .1s linear;
  }

  .btn-primary {
    padding: 12px 14px;
    float: right;
    clear: both;
  }
}
