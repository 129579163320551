@font-face {
    font-family: 'Frutiger Neue';
    font-style: normal;
    font-weight: 400;
    src: url("#{$font-path}/FrutigerNeueLTW06-Book.eot");
    src: url("#{$font-path}/FrutigerNeueLTW06-Book.eot") format("eot"),
    url("#{$font-path}/FrutigerNeueLTW06-Book.woff2") format("woff2"),
    url("#{$font-path}/FrutigerNeueLTW06-Book.woff") format("woff"),
    url("#{$font-path}/FrutigerNeueLTW06-Book.ttf") format("truetype");
}

@font-face {
    font-family: 'Frutiger Neue';
    font-style: normal;
    font-weight: 600;
    src: url("#{$font-path}/FrutigerNeueLTW06-Bold.eot");
    src: url("#{$font-path}/FrutigerNeueLTW06-Bold.eot") format("eot"),
    url("#{$font-path}/FrutigerNeueLTW06-Bold.woff2") format("woff2"),
    url("#{$font-path}/FrutigerNeueLTW06-Bold.woff") format("woff"),
    url("#{$font-path}/FrutigerNeueLTW06-Bold.ttf") format("truetype");
}
