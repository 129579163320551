//
// INTICON
// --------------------------------------------------

@font-face {
  font-family: '#{$icon-font-family}';
  src:  url('#{$font-path}/#{$icon-font-family}.eot?nfz0p3');
  src:  url('#{$font-path}/#{$icon-font-family}.eot?nfz0p3#iefix') format('embedded-opentype'),
  url('#{$font-path}/#{$icon-font-family}.woff2?nfz0p3') format('woff2'),
  url('#{$font-path}/#{$icon-font-family}.ttf?nfz0p3') format('truetype'),
  url('#{$font-path}/#{$icon-font-family}.woff?nfz0p3') format('woff'),
  url('#{$font-path}/#{$icon-font-family}.svg?nfz0p3##{$icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin inticon() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Add this to make icons more flexible */
  display: inline-block;
  position: relative;
  top: 1px;
}

[class^="ui-"],
[class*=" ui-"] {
  @include inticon();
}

// Add new icons here, also don't forget to add the variables

.ui-abspielen {
  &:before {
    content: $ui-abspielen;
  }
}
.ui-anhang {
  &:before {
    content: $ui-anhang;
  }
}
.ui-antworten {
  &:before {
    content: $ui-antworten;
  }
}
.ui-at-zeichen {
  &:before {
    content: $ui-at-zeichen;
  }
}
.ui-ausrufezeichen {
  &:before {
    content: $ui-ausrufezeichen;
  }
}
.ui-ausrufezeichen-rund {
  &:before {
    content: $ui-ausrufezeichen-rund;
  }
}
.ui-batterie-halbvoll {
  &:before {
    content: $ui-batterie-halbvoll;
  }
}
.ui-batterie-leer {
  &:before {
    content: $ui-batterie-leer;
  }
}
.ui-batterie-voll {
  &:before {
    content: $ui-batterie-voll;
  }
}
.ui-bestaetigen {
  &:before {
    content: $ui-bestaetigen;
  }
}
.ui-bestaetigen-rund {
  &:before {
    content: $ui-bestaetigen-rund;
  }
}
.ui-bild {
  &:before {
    content: $ui-bild;
  }
}
.ui-briefkasten-leer {
  &:before {
    content: $ui-briefkasten-leer;
  }
}
.ui-briefkasten-voll {
  &:before {
    content: $ui-briefkasten-voll;
  }
}
.ui-checkbox {
  &:before {
    content: $ui-checkbox;
  }
}
.ui-cloud-download {
  &:before {
    content: $ui-cloud-download;
  }
}
.ui-cloud-upload {
  &:before {
    content: $ui-cloud-upload;
  }
}
.ui-code {
  &:before {
    content: $ui-code;
  }
}
.ui-dashboard {
  &:before {
    content: $ui-dashboard;
  }
}
.ui-daumen-hoch {
  &:before {
    content: $ui-daumen-hoch;
  }
}
.ui-daumen-neutral {
  &:before {
    content: $ui-daumen-neutral;
  }
}
.ui-daumen-runter {
  &:before {
    content: $ui-daumen-runter;
  }
}
.ui-dialog {
  &:before {
    content: $ui-dialog;
  }
}
.ui-diamant {
  &:before {
    content: $ui-diamant;
  }
}
.ui-dividieren {
  &:before {
    content: $ui-dividieren;
  }
}
.ui-dividieren-rund {
  &:before {
    content: $ui-dividieren-rund;
  }
}
.ui-dokument {
  &:before {
    content: $ui-dokument;
  }
}
.ui-dokument-ablehnen {
  &:before {
    content: $ui-dokument-ablehnen;
  }
}
.ui-dokument-aendern {
  &:before {
    content: $ui-dokument-aendern;
  }
}
.ui-dokument-bestaetigen {
  &:before {
    content: $ui-dokument-bestaetigen;
  }
}
.ui-dokument-vorlesen {
  &:before {
    content: $ui-dokument-vorlesen;
  }
}
.ui-download {
  &:before {
    content: $ui-download;
  }
}
.ui-drucken {
  &:before {
    content: $ui-drucken;
  }
}
.ui-duplizieren {
  &:before {
    content: $ui-duplizieren;
  }
}
.ui-e-mail {
  &:before {
    content: $ui-e-mail;
  }
}
.ui-e-mail-abschicken {
  &:before {
    content: $ui-e-mail-abschicken;
  }
}
.ui-e-mail-ausrufezeichen {
  &:before {
    content: $ui-e-mail-ausrufezeichen;
  }
}
.ui-e-mail-weiterleiten {
  &:before {
    content: $ui-e-mail-weiterleiten;
  }
}
.ui-einkaufswagen {
  &:before {
    content: $ui-einkaufswagen;
  }
}
.ui-einstellungen {
  &:before {
    content: $ui-einstellungen;
  }
}
.ui-externer-link {
  &:before {
    content: $ui-externer-link;
  }
}
.ui-falsch {
  &:before {
    content: $ui-falsch;
  }
}
.ui-filter-leer {
  &:before {
    content: $ui-filter-leer;
  }
}
.ui-filter-voll {
  &:before {
    content: $ui-filter-voll;
  }
}
.ui-frage-antwort {
  &:before {
    content: $ui-frage-antwort;
  }
}
.ui-fragezeichen {
  &:before {
    content: $ui-fragezeichen;
  }
}
.ui-fragezeichen-rund {
  &:before {
    content: $ui-fragezeichen-rund;
  }
}
.ui-gleich {
  &:before {
    content: $ui-gleich;
  }
}
.ui-gleich-rund {
  &:before {
    content: $ui-gleich-rund;
  }
}
.ui-glocke {
  &:before {
    content: $ui-glocke;
  }
}
.ui-gluecklich {
  &:before {
    content: $ui-gluecklich;
  }
}
.ui-gps {
  &:before {
    content: $ui-gps;
  }
}
.ui-infozeichen {
  &:before {
    content: $ui-infozeichen;
  }
}
.ui-kurs-chart {
  &:before {
    content: $ui-kurs-chart;
  }
}
.ui-lautstaerke {
  &:before {
    content: $ui-lautstaerke;
  }
}
.ui-liste {
  &:before {
    content: $ui-liste;
  }
}
.ui-loeschen {
  &:before {
    content: $ui-loeschen;
  }
}
.ui-logout {
  &:before {
    content: $ui-logout;
  }
}
.ui-lupe {
  &:before {
    content: $ui-lupe;
  }
}
.ui-mehrfunktionen {
  &:before {
    content: $ui-mehrfunktionen;
  }
}
.ui-menue {
  &:before {
    content: $ui-menue;
  }
}
.ui-merken {
  &:before {
    content: $ui-merken;
  }
}
.ui-minus {
  &:before {
    content: $ui-minus;
  }
}
.ui-minus-rund {
  &:before {
    content: $ui-minus-rund;
  }
}
.ui-mond {
  &:before {
    content: $ui-mond;
  }
}
.ui-mute {
  &:before {
    content: $ui-mute;
  }
}
.ui-neu-laden {
  &:before {
    content: $ui-neu-laden;
  }
}
.ui-neuer-link {
  &:before {
    content: $ui-neuer-link;
  }
}
.ui-neues-fenster {
  &:before {
    content: $ui-neues-fenster;
  }
}
.ui-online {
  &:before {
    content: $ui-online;
  }
}
.ui-ordner {
  &:before {
    content: $ui-ordner;
  }
}
.ui-passwort {
  &:before {
    content: $ui-passwort;
  }
}
.ui-pause {
  &:before {
    content: $ui-pause;
  }
}
.ui-pfeil-links {
  &:before {
    content: $ui-pfeil-links;
  }
}
.ui-pfeil-liob {
  &:before {
    content: $ui-pfeil-liob;
  }
}
.ui-pfeil-liun {
  &:before {
    content: $ui-pfeil-liun;
  }
}
.ui-pfeil-oben {
  &:before {
    content: $ui-pfeil-oben;
  }
}
.ui-pfeil-rechts {
  &:before {
    content: $ui-pfeil-rechts;
  }
}
.ui-pfeil-reob {
  &:before {
    content: $ui-pfeil-reob;
  }
}
.ui-pfeil-reun {
  &:before {
    content: $ui-pfeil-reun;
  }
}
.ui-pfeil-unten {
  &:before {
    content: $ui-pfeil-unten;
  }
}
.ui-pfeilspitze-links {
  &:before {
    content: $ui-pfeilspitze-links;
  }
}
.ui-pfeilspitze-oben {
  &:before {
    content: $ui-pfeilspitze-oben;
  }
}
.ui-pfeilspitze-rechts {
  &:before {
    content: $ui-pfeilspitze-rechts;
  }
}
.ui-pfeilspitze-unten {
  &:before {
    content: $ui-pfeilspitze-unten;
  }
}
.ui-pin {
  &:before {
    content: $ui-pin;
  }
}
.ui-plus {
  &:before {
    content: $ui-plus;
  }
}
.ui-plus-rund {
  &:before {
    content: $ui-plus-rund;
  }
}
.ui-position {
  &:before {
    content: $ui-position;
  }
}
.ui-postbox {
  &:before {
    content: $ui-postbox;
  }
}
.ui-postbox-download {
  &:before {
    content: $ui-postbox-download;
  }
}
.ui-profil {
  &:before {
    content: $ui-profil;
  }
}
.ui-regen {
  &:before {
    content: $ui-regen;
  }
}
.ui-risiko {
  &:before {
    content: $ui-risiko;
  }
}
.ui-rss {
  &:before {
    content: $ui-rss;
  }
}
.ui-schieberegler {
  &:before {
    content: $ui-schieberegler;
  }
}
.ui-schliessen {
  &:before {
    content: $ui-schliessen;
  }
}
.ui-schliessen-rund {
  &:before {
    content: $ui-schliessen-rund;
  }
}
.ui-schloss-geschlossen {
  &:before {
    content: $ui-schloss-geschlossen;
  }
}
.ui-schloss-offen {
  &:before {
    content: $ui-schloss-offen;
  }
}
.ui-scrollen {
  &:before {
    content: $ui-scrollen;
  }
}
.ui-sonne {
  &:before {
    content: $ui-sonne;
  }
}
.ui-sonne-wolken {
  &:before {
    content: $ui-sonne-wolken;
  }
}
.ui-sortieren-absteigend {
  &:before {
    content: $ui-sortieren-absteigend;
  }
}
.ui-sortieren-aufsteigend {
  &:before {
    content: $ui-sortieren-aufsteigend;
  }
}
.ui-sprechblase {
  &:before {
    content: $ui-sprechblase;
  }
}
.ui-sprungmarke {
  &:before {
    content: $ui-sprungmarke;
  }
}
.ui-start {
  &:before {
    content: $ui-start;
  }
}
.ui-stern-leer {
  &:before {
    content: $ui-stern-leer;
  }
}
.ui-stern-voll {
  &:before {
    content: $ui-stern-voll;
  }
}
.ui-stopp {
  &:before {
    content: $ui-stopp;
  }
}
.ui-tag {
  &:before {
    content: $ui-tag;
  }
}
.ui-teilen {
  &:before {
    content: $ui-teilen;
  }
}
.ui-telefon {
  &:before {
    content: $ui-telefon;
  }
}
.ui-tippen {
  &:before {
    content: $ui-tippen;
  }
}
.ui-traurig {
  &:before {
    content: $ui-traurig;
  }
}
.ui-uebersicht {
  &:before {
    content: $ui-uebersicht;
  }
}
.ui-uhr {
  &:before {
    content: $ui-uhr;
  }
}
.ui-vergroessern {
  &:before {
    content: $ui-vergroessern;
  }
}
.ui-verkleinern {
  &:before {
    content: $ui-verkleinern;
  }
}
.ui-verschieben {
  &:before {
    content: $ui-verschieben;
  }
}
.ui-webseite {
  &:before {
    content: $ui-webseite;
  }
}
.ui-weltkugel {
  &:before {
    content: $ui-weltkugel;
  }
}
.ui-werkzeug {
  &:before {
    content: $ui-werkzeug;
  }
}
.ui-wischen {
  &:before {
    content: $ui-wischen;
  }
}
.ui-wlan {
  &:before {
    content: $ui-wlan;
  }
}
.ui-zum-ende {
  &:before {
    content: $ui-zum-ende;
  }
}
.ui-zum-start {
  &:before {
    content: $ui-zum-start;
  }
}
.ui-sc-facebook {
  &:before {
    content: $ui-sc-facebook;
  }
}
.ui-sc-linkedin {
  &:before {
    content: $ui-sc-linkedin;
  }
}
.ui-sc-twitter {
  &:before {
    content: $ui-sc-twitter;
  }
}
.ui-sc-vimeo {
  &:before {
    content: $ui-sc-vimeo;
  }
}
.ui-sc-whatsapp {
  &:before {
    content: $ui-sc-whatsapp;
  }
}
.ui-sc-xing {
  &:before {
    content: $ui-sc-xing;
  }
}
.ui-sc-youtube {
  &:before {
    content: $ui-sc-youtube;
  }
}

