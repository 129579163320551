@import './login';

#nav-main-top {
  display: flex;
  align-items: center;
}

.navbar-nav {
  .nav-item {
    .nav-link {
      font-weight: bold;
      height: 100%;
    }

    &:hover,
    &:focus {
      .nav-link {
        background-color: $gray-200;
      }
    }

    &.active {
      .nav-link {
        color: $secondary;
      }
    }

    @include media-breakpoint-down(sm) {
      &.logout {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}


.navbar {
  background-color: #ffffff !important;
  box-shadow: 0 2px 3px rgba(64, 64, 64, .45);
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 0;

    .container {
      flex-wrap: wrap;
    }

    .navbar-collapse {
      min-width: 100%;
      margin: 0 (-$navbar-nav-link-padding-x);
    }
  }

  #brand-logo {
    height: 52px;
  }

  @include media-breakpoint-down(sm) {
    #brand-logo {
      height: 41px;
    }

    .navbar-brand {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .navbar-toggler {
    border: 0;
    padding: 0;

    i {
      font-size: 42px;
      color: $primary;
    }
  }
}

.ui-logo {
  background-image: url("../assets/ui-logo.svg");
  height: 60px;
  width: 200px;
  background-repeat: no-repeat;
}

// Fix weird bootstrap padding prune behaviour

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
}

h1, h2, h3 {
  color: $primary;

  b, strong {
    color: $secondary;
  }
}

hr {
  clear: both;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
}

.img-container {
  padding-top: 4px; // Align image with text
  margin-bottom: $paragraph-margin-bottom;
}

.union-breadcrumb {
  margin-top: 20px;
  padding-bottom: 12px;

  svg {
    height: 12px;
  }

  span {
    color: $primary;
    text-decoration: underline;
  }

  span:last-child {
    color: $secondary;
    text-decoration: unset;
  }
}

.main {
  padding-bottom: $spacer;
}

.area {
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;
}

.footer-container {
  background-color: $gray-200;
}

.container > .block-separator:first-of-type {
  display: none; // hide the first hr rendered by foreach in python, maybe refactor to use python logic instead
}

#navbarSupportedContent {
  align-items: stretch;
}