//
// INTICON VARIABLES
// --------------------------------------------------
$icon-font-family: "ui-icon";

$ui-abspielen: "\e900";
$ui-anhang: "\e901";
$ui-antworten: "\e902";
$ui-at-zeichen: "\e903";
$ui-ausrufezeichen: "\e904";
$ui-ausrufezeichen-rund: "\e905";
$ui-batterie-halbvoll: "\e906";
$ui-batterie-leer: "\e907";
$ui-batterie-voll: "\e908";
$ui-bestaetigen: "\e909";
$ui-bestaetigen-rund: "\e90a";
$ui-bild: "\e90b";
$ui-briefkasten-leer: "\e90c";
$ui-briefkasten-voll: "\e90d";
$ui-checkbox: "\e90e";
$ui-cloud-download: "\e90f";
$ui-cloud-upload: "\e910";
$ui-code: "\e911";
$ui-dashboard: "\e912";
$ui-daumen-hoch: "\e913";
$ui-daumen-neutral: "\e914";
$ui-daumen-runter: "\e915";
$ui-dialog: "\e916";
$ui-diamant: "\e917";
$ui-dividieren: "\e918";
$ui-dividieren-rund: "\e919";
$ui-dokument: "\e91a";
$ui-dokument-ablehnen: "\e91b";
$ui-dokument-aendern: "\e91c";
$ui-dokument-bestaetigen: "\e91d";
$ui-dokument-vorlesen: "\e91e";
$ui-download: "\e91f";
$ui-drucken: "\e920";
$ui-duplizieren: "\e921";
$ui-e-mail: "\e922";
$ui-e-mail-abschicken: "\e923";
$ui-e-mail-ausrufezeichen: "\e924";
$ui-e-mail-weiterleiten: "\e925";
$ui-einkaufswagen: "\e926";
$ui-einstellungen: "\e927";
$ui-externer-link: "\e928";
$ui-falsch: "\e929";
$ui-filter-leer: "\e92a";
$ui-filter-voll: "\e92b";
$ui-frage-antwort: "\e92c";
$ui-fragezeichen: "\e92d";
$ui-fragezeichen-rund: "\e92e";
$ui-gleich: "\e92f";
$ui-gleich-rund: "\e930";
$ui-glocke: "\e931";
$ui-gluecklich: "\e932";
$ui-gps: "\e933";
$ui-infozeichen: "\e934";
$ui-kurs-chart: "\e935";
$ui-lautstaerke: "\e936";
$ui-liste: "\e937";
$ui-loeschen: "\e938";
$ui-logout: "\e939";
$ui-lupe: "\e93a";
$ui-mehrfunktionen: "\e93b";
$ui-menue: "\e93c";
$ui-merken: "\e93d";
$ui-minus: "\e93e";
$ui-minus-rund: "\e93f";
$ui-mond: "\e940";
$ui-mute: "\e941";
$ui-neu-laden: "\e942";
$ui-neuer-link: "\e943";
$ui-neues-fenster: "\e944";
$ui-online: "\e945";
$ui-ordner: "\e946";
$ui-passwort: "\e947";
$ui-pause: "\e948";
$ui-pfeil-links: "\e949";
$ui-pfeil-liob: "\e94a";
$ui-pfeil-liun: "\e94b";
$ui-pfeil-oben: "\e94c";
$ui-pfeil-rechts: "\e94d";
$ui-pfeil-reob: "\e94e";
$ui-pfeil-reun: "\e94f";
$ui-pfeil-unten: "\e950";
$ui-pfeilspitze-links: "\e951";
$ui-pfeilspitze-oben: "\e952";
$ui-pfeilspitze-rechts: "\e953";
$ui-pfeilspitze-unten: "\e954";
$ui-pin: "\e955";
$ui-plus: "\e956";
$ui-plus-rund: "\e957";
$ui-position: "\e958";
$ui-postbox: "\e959";
$ui-postbox-download: "\e95a";
$ui-profil: "\e95b";
$ui-regen: "\e95c";
$ui-risiko: "\e95d";
$ui-rss: "\e95e";
$ui-schieberegler: "\e95f";
$ui-schliessen: "\e960";
$ui-schliessen-rund: "\e961";
$ui-schloss-geschlossen: "\e962";
$ui-schloss-offen: "\e963";
$ui-scrollen: "\e964";
$ui-sonne: "\e965";
$ui-sonne-wolken: "\e966";
$ui-sortieren-absteigend: "\e967";
$ui-sortieren-aufsteigend: "\e968";
$ui-sprechblase: "\e969";
$ui-sprungmarke: "\e96a";
$ui-start: "\e96b";
$ui-stern-leer: "\e96c";
$ui-stern-voll: "\e96d";
$ui-stopp: "\e96e";
$ui-tag: "\e96f";
$ui-teilen: "\e970";
$ui-telefon: "\e971";
$ui-tippen: "\e972";
$ui-traurig: "\e973";
$ui-uebersicht: "\e974";
$ui-uhr: "\e975";
$ui-vergroessern: "\e976";
$ui-verkleinern: "\e977";
$ui-verschieben: "\e978";
$ui-webseite: "\e979";
$ui-weltkugel: "\e97a";
$ui-werkzeug: "\e97b";
$ui-wischen: "\e97c";
$ui-wlan: "\e97d";
$ui-zum-ende: "\e97e";
$ui-zum-start: "\e97f";
$ui-sc-facebook: "\e980";
$ui-sc-linkedin: "\e981";
$ui-sc-twitter: "\e982";
$ui-sc-vimeo: "\e983";
$ui-sc-whatsapp: "\e984";
$ui-sc-xing: "\e985";
$ui-sc-youtube: "\e986";
